import { AppProps /*, AppContext */ } from "next/app";
import MessengerChat from "../components/MessengerChat";
import "../styles/index.css";
import '../services/sentry';
import PlausibleProvider from 'next-plausible'

export default function App({ Component, pageProps, err }: AppProps & { err: any }): JSX.Element {
  return (
    <>
      <PlausibleProvider domain="bmxdevils.be" customDomain="https://stats.bmxdevils.be">
        <Component {...pageProps} err={err} />
      </PlausibleProvider>
      <MessengerChat pageId="116907488458968" />
    </>
  );
}
